<template>
    <v-card max-width="1200" class="mx-auto elevation-1" min-height="100vh">
      <v-layout>                
        <AppBar />
        <v-main>
          <v-container fluid>
            <!-- Hero Banner -->
            <v-sheet class="hero-banner rounded-xl d-flex align-center justify-between pa-8 mb-10">
              <div>
                <h4 class="text-h4 font-weight-bold mb-2">Invites</h4>
                <p class="text-body-1 mb-4">
                  Share the entertainment, invite a friend.
                </p>           
                <v-btn color="pink" class="ml-5" v-on:click="dialog=!dialog" :disabled="invites_count<=0">
                    New Invite
                  </v-btn>
                  <b class="ml-5">Available:</b> {{invites_count}}
              </div>
            </v-sheet>
            
        <div class="text-subtitle-1 text-medium-emphasis"></div>
          
        <v-card>
          <v-card class="elevation-0 pb-5">                                                               
            <template>
            <div class="text-center pa-4">
              <v-dialog
                v-model="dialog"
                max-width="400"
                persistent
              >
                

                <v-card
                  prepend-icon="mdi-map-marker"
                  text="Submit the e-mailaddress of the person you would like to invite"
                  title="New Invite"
                >
                <v-card style="padding:5px;" class="evelvation-0">
                <v-text-field                
                label="E-mailaddress"
                prepend-inner-icon="mdi-email-outline"
                variant="outlined"
                hide-details
                single-line
                v-model="invite_email"
              ></v-text-field>
            </v-card>
                  <template v-slot:actions>
                    <v-spacer></v-spacer>

                    <v-btn @click="dialog = false">
                      Cancel
                    </v-btn>

                    <v-btn @click="SendInvite()">
                      Send
                    </v-btn>
                  </template>
                </v-card>
              </v-dialog>
            </div>
          </template>


          </v-card>
                <template v-slot:text>
              <v-text-field
                v-model="search"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                hide-details
                single-line
              ></v-text-field>
            </template>
                <v-data-table style="background-color: rgba(0,0,0,0.0);" 
                  calculate-widths="false"
                  
                    :headers="headers"
                    :items="invites"
                    :search="search"                      
                      
                  ></v-data-table>
              </v-card>
 
                                            
          </v-container>
        </v-main>
      </v-layout>
    </v-card>
  </template>

<style scoped>
.hero-banner {
  background: linear-gradient(to right, #4a90e2, #13dbfe);
  color: white;
}

.status-card {
  background: #f3f7fa;
}

.news-card {
  background: #e8f5ff;
}

.updates-card {
  background: #ffffff;
}

.v-btn {
  text-transform: none;
  font-size: 16px;
}

.v-card:hover {
  
}
</style>

<script>
import { useUserStore } from '../stores/user'
import AppBar from '../components/AppBar.vue'
import axios from "axios";

export default {
  data: () => ({
    headers: [{ title: 'Used', value: 'used',align: 'start', width: 30},
                  { title: 'Code', value: 'code' },
                  { title: 'Invited User', value: 'invite_mail' },
                  { title: 'Date', value: 'created' }
              ],
        invites: [],
        search:"",
        invite_email:"",
        dialog: false,
        invites_count: 0,
        user: null,
    }),
  components: {
    AppBar
    },
    mounted(){
      this.user = useUserStore();  
      const { id, token, email} = this.user; // Extract only needed fields
      this.invites_count = this.user.invites_count;
      let UserData = {
               user_id: this.user.id,
               user: { id, email, token },
            }    

            this.$axios
        .post('/jfin.tv/my_invites.php', UserData)
        .then((response) => {
            if(response.data.status == 0)
                alert(response.data.reason);
                else
                {
                    
                    this.invites = response.data.invites;         
                }
        })
        .catch((error) => {
          // Handle errors
          if (error.response) {
            this.error = `Error ${error.response.status}: ${error.response.data.message}`;
          } else {
            this.error = error.message;
          }
        })
        .finally(() => {
          // Always executed after success or error
          
        });      

    
    },
    methods:{
    SendInvite(){
      this.dialog = false;
      const { id, token, email} = this.user; // Extract only needed fields 
      let InviteData = {
               invite_by: this.user.id,
               code: this.generateSerialNumber(),
               mail: this.invite_email,
               user: { id, email, token },
            } 
              if(this.isValidEmail(this.invite_email))
            {
              axios.defaults.baseURL = 'https://mediatrunk.net'; // Base API URL
              axios.post("https://mediatrunk.net/jfin.tv/new_invite.php",InviteData,{ headers: {'Content-Type': 'application/json',}})
              .then(response => {
                  
                  if(response.data.status == 0)
                  alert(response.data.reason);
                  else
                  {
                    alert(response.data.reason);    
                  }
              })
              .catch(error => {
                  this.errorMessage = error.message;
                  console.error("There was an error!", error);
              });
            }else
            console.log("Mail is invalid");
            

    },
   isValidEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  },
    generateSerialNumber() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return [...Array(3)].map(() => chars[Math.floor(Math.random() * chars.length)]).join('') + '-' +
          [...Array(4)].map(() => chars[Math.floor(Math.random() * chars.length)]).join('') + '-' +
          [...Array(4)].map(() => chars[Math.floor(Math.random() * chars.length)]).join('') + '-' +
          [...Array(3)].map(() => chars[Math.floor(Math.random() * chars.length)]).join('');
  }
  }

}
</script>