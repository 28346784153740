
<template>
    <MainForm/>
  </template>
  <script>
  import MainForm from '../components/MainForm.vue'
  
  export default {
    name: 'LoginPage',
  
    components: {
      MainForm,
    },
  
    data: () => ({
      
    }),
  }
  </script>