
import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: 'https://mediatrunk.net', // Replace with your API base URL
  timeout: 10000, // Set a timeout in milliseconds
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Return the response as is for successful requests
    return response;
  },
  (error) => {
    // Handle errors globally
    if (error.response) {
      const status = error.response.status;
      switch (status) {
        case 404:
          console.error('Resource not found (404)');
          // You can redirect, show a notification, or handle the error here
          break;
        case 301:{
                  let user = window.store.user;
                  user.signOut();
                }          
          break;
        case 500:
          console.error('Internal server error (500)');
          // Handle 500-specific logic
          break;
        default:
          console.error(`HTTP Error: ${status}`);
      }
    } else if (error.request) {
      console.error('No response received from server');
    } else {
      console.error('Request setup error:', error.message);
    }

    // Always reject the error to propagate it for further handling
    return Promise.reject(error);
  }
);

// Export the plugin
export default {
  install(app) {
    // Make the Axios instance available globally
    app.config.globalProperties.$axios = axiosInstance;
  },
};
