<template>
    <div class="mt-10">  
        <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
    <v-tabs
      v-model="tab" 
    >
      <v-tab value="one"> <v-icon>mdi-login</v-icon>Login</v-tab>
      <v-tab value="two"><v-icon>mdi-account-plus</v-icon>Sign Up</v-tab>      
       
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="one">
          <LoginForm @changeTabEvent="changeTab" />
        </v-window-item>

        <v-window-item value="two" >
          <RegisterForm  @changeTabEvent="changeTab"/>
        </v-window-item>

      </v-window>
    </v-card-text>
  </v-card>

    </div>
  </template>
  <script>
  import LoginForm from './LoginForm.vue'
  import RegisterForm from './RegisterForm.vue'
  
  
  export default {
    data: () => ({
        tab: null,
      visible: false,
    }),
    methods:{
        changeTab(newTab){            
            this.tab = newTab;
        }
    },



    components: {
        LoginForm,
        RegisterForm,
        
    },
 
  }
  </script>
  <style>

  </style>