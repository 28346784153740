<template>
  
  <v-card max-width="1200" class="mx-auto elevation-1" min-height="100vh">
      <v-layout>                
        <AppBar />
        <v-main>


          <v-container class="py-12">
    <!-- Hero Banner -->
    <v-sheet class="hero-banner rounded-xl d-flex align-center justify-between pa-8 mb-10">
      <div>
        <h1 class="text-h3 font-weight-bold mb-2">Welcome Back, {{ user.name }}!</h1>
        <p class="text-body-1 mb-4">
          {{AccountInfo.Message}}
        </p>
        <v-btn color="primary" v-if="AccountInfo.Target != undefined" rounded class="px-6 py-3 text-white font-weight-medium" :to="AccountInfo.Target">
          {{ AccountInfo.Buttontext }}
        </v-btn>
      </div>
      <v-img
        
        class="rounded-lg"
        height="200"
        width="300"
        alt="Technology Image"
      ></v-img>
    </v-sheet>

    <!-- Grid Layout for Account Status and News -->
    <v-row dense>
      <!-- Account Status -->
      <v-col cols="12" md="4">
        <v-card class="status-card rounded-lg pa-6 elevation-2 d-flex flex-column justify-center" style="min-height:200px;">
          <div class="d-flex align-center mb-3">
            <v-icon size="36" color="green">mdi-account-circle-outline</v-icon>
            <h3 class="text-h6 font-weight-bold ml-3">Account Status</h3>
          </div>
          <p class="text-body-2">Renewed: <strong>{{ user.last_payment }}</strong></p>
          
          <v-btn color="green" rounded text class="mt-4 font-weight-bold" to="/billing">Manage Billing</v-btn>
        </v-card>
      </v-col>
      

      <!-- Support Section -->
      <v-col cols="12" md="8">
        <v-card class="news-card rounded-lg pa-6 elevation-2" style="min-height:200px;">
          <div>
        <h2 class="text-h5 font-weight-bold mb-2">Support</h2>
        <p class="text-body-1 mb-4">
          Join our discord for help and support.           
        </p>
        <v-btn color="primary" text class="font-weight-bold" @click="Launch('https://discord.gg/BRD6awkB4n')">Join Discord</v-btn>
      </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <!-- News Section -->
      <v-col cols="12" md="12">
        <v-card class="news-card rounded-lg pa-6 elevation-2">
          <div class="d-flex align-center mb-3">
            <v-icon size="36" color="blue">mdi-newspaper-variant-outline</v-icon>
            <h3 class="text-h6 font-weight-bold ml-3">Latest News</h3>
          </div>
          <v-row dense>
              <v-col
                v-for="(item) in this.News" :key="item.id"                
                cols="12"
              >
                <v-card
                  :title="`${item.title}`"
                  :subtitle="`Posted: ${item.news_date} - Admin`"
                  :text="`${item.text}`"
                ></v-card>
              </v-col>
            </v-row>          
        </v-card>
      </v-col>
    </v-row>

  </v-container>



        </v-main>
      </v-layout>
    </v-card>
  </template>


<style scoped>
.hero-banner {
  background: linear-gradient(to right, #4a90e2, #9013fe);
  color: white;
}

.status-card {
  background: #f3f7fa;
}

.news-card {
  background: #e8f5ff;
}

.updates-card {
  background: #ffffff;
}

.v-btn {
  text-transform: none;
  font-size: 16px;
}

.v-card:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
}
</style>



<script>
import { useUserStore } from '../stores/user'
import AppBar from '../components/AppBar.vue'


export default {
  data: () => ({
      News: [],
      AccountInfo: {
                Message: "",
                Buttontext: "",
                Target: undefined,
      }      
    }),
  setup() {
    const user = useUserStore();        
    return{user}
  },  
  computed:{
    isExpired: function()
    {
      return Date.parse(this.user.expiry_date)-Date.parse(new Date())<0 ? true: false;
    },
    expiryDate: function()
    {
      return this.user.expiry_date; 
    }

  },

  components: {
    AppBar
    },
    mounted(){
      this.GetNews();      
      
      if(this.isExpired)
        this.AccountInfo =  { Message: "Your account needs some attention!",
                              Buttontext: "Go to Billing",
                              Target: "/billing" 
                            };
      else
        this.AccountInfo =  { Message: "Your account is in good standing. Go to servers to find out more",
                              Buttontext: "Go To Servers",
                              Target: "/server" 
                            };


    },
    
    methods:{
    Launch(URL){
      window.open(URL,'_blank');                    
    },
    GetNews()
    {         
      const { id, token, email} = this.user; // Extract only needed fields

      let NewsData = {
        action:"GET",        
        user: { id, email, token },
            }       
            
      // Use Axios promise-based syntax
      this.$axios
        .post('/jfin.tv/news.php', NewsData)
        .then((response) => {
          this.$nextTick(() => {         
                
                this.News = response.data.data;                        
              });
        })
        .catch((error) => {
          // Handle errors
          if (error.response) {
            this.error = `Error ${error.response.status}: ${error.response.data.message}`;
          } else {
            this.error = error.message;
          }
        })
        .finally(() => {
          // Always executed after success or error
          
        });
        
            
    },
  }

}
</script>
